import { Routes } from '@angular/router';

export const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./components/standard/standard.routing').then((mod) => mod.STANDARD_ROUTES),
        data: {
            uid: 'startseite'
        }
    }
];
