import { Injectable } from '@angular/core';
import { ImageField, KeyTextField, LinkField, PrismicDocument, SliceZone } from '@prismicio/client';
import { BehaviorSubject, from, map } from 'rxjs';
import { environment } from '../../environments/environment';

//TODO: CHECK Document Type and UID and replace <any> with correct type

const GLOBAL_CONTENT = 'global-content';
const GLOBAL_CONTENT_UID = 'global-content';

@Injectable({
    providedIn: 'root'
})
export class GlobalContentService {
    private globalContent = new BehaviorSubject<GlobalContent | undefined>(undefined);

    constructor() {
        this.fetchGlobalContent().subscribe((globalContent) => {
            this.globalContent.next(globalContent);
        });
    }

    getGlobalContent(): BehaviorSubject<any | undefined> {
        return this.globalContent;
    }

    private fetchGlobalContent() {
        return from(environment.prismic.client.getSingle<PrismicDocument<GlobalContent>>(GLOBAL_CONTENT)).pipe(
            map((result) => {
                return result.data;
            })
        );
    }
}

export type GlobalContent = {
    logo: ImageField;
    body?: SliceZone<any>;
};

export type MenuItem = {
    title: KeyTextField;
    link: LinkField;
    icon: ImageField;
    fragment?: KeyTextField;
};

export type SocialLink = {
    icon: ImageField;
    link: LinkField;
};
